.grid-item-container {
    width: 100%;
    text-align: center;
    cursor: pointer;
}

.link-box {
    cursor: pointer;
}

.thumbnail {
    width: 100%;
    border-radius: 12px;
}

.title {
    margin-top: 1rem;
    font-size: 2rem;
}

.title {
    font-size: 1.4rem;
}