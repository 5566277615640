.page-container {
    margin: 5rem 0rem 0rem 0rem
}

.title-card {
    text-align: center;
}

.full-name {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%
}


.helper-text {
    display: flex;
    height: 1.3rem;
}

.sub-btn {
    margin-top: 1rem;
}

.submit-helper-text {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    /* text-align: center; */
}

.footer {
    /* position: absolute; */
    width: 100%;
}

.link-container {
    display: flex;
    flex-direction: row;
    justify-content:space-between;

}